export function dateCompare(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  let years = end.getFullYear() - start.getFullYear();
  let months = end.getMonth() - start.getMonth();

  if (months < 0) {
    years -= 1;
    months += 12;
  }

  // 1년 미만일 경우 개월 수만 반환
  if (years === 0) {
    return `${months}개월`;
  }

  // 개월 수가 0이면 "X년" 형태로 반환
  return months === 0 ? `${years}년` : `${years}년 ${months}개월`;
}
