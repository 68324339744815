import classNames from "classnames/bind";

import style from "./Profile.module.scss";

const cx = classNames.bind(style);

type Props = {
  title?: any;
  name?: any;
  id?: any;
};

const Profile: React.FC<Props> = ({ title, name, id }: Props) => {
  return (
    <>
      <div className={cx("main_profile")} id={id}>
        <div className={cx("main_profile_inner")}>
          <h3 className={cx("main_profile_title")}>프로필</h3>

          <div className={cx("main_profile_introduce")}>
            <ol className={cx("main_introduce_list", "type_basic")}>
              <li className={cx("main_list_item")}>
                <dl className={cx("main_item_description")}>
                  <dt className={cx("main_description_title")}>이름</dt>
                  <dd className={cx("main_description_data")}>박 재선</dd>
                </dl>
              </li>
              <li className={cx("main_list_item")}>
                <dl className={cx("main_item_description")}>
                  <dt className={cx("main_description_title")}>생년월일</dt>
                  <dd className={cx("main_description_data")}>1989.02.22.</dd>
                </dl>
              </li>
              <li className={cx("main_list_item")}>
                <dl className={cx("main_item_description")}>
                  <dt className={cx("main_description_title")}>연락처</dt>
                  <dd className={cx("main_description_data")}>
                    <ul className={cx("main_data_list", "type_basic")}>
                      <li className={cx("main_list_item")}>
                        <a
                          href="tel: 010-4464-3123"
                          className={cx("main_item_link", "type_contact")}
                        >
                          📱 Phone : 010-4464-3123
                        </a>
                      </li>
                      <li className={cx("main_list_item")}>
                        <a
                          href="mailto: admin@cony.kr"
                          className={cx("main_item_link", "type_contact")}
                        >
                          📧 Email : admin@cony.kr
                        </a>
                      </li>
                      <li className={cx("main_list_item")}>
                        <a
                          href="https://www.linkedin.com/in/%EC%9E%AC%EC%84%A0-%EB%B0%95-2649271a7/"
                          target="_blank"
                          className={cx("main_item_link", "type_contact")}
                        >
                          Linkedin :
                          https://www.linkedin.com/in/%EC%9E%AC%EC%84%A0-%EB%B0%95-2649271a7/
                        </a>
                      </li>
                    </ul>
                  </dd>
                </dl>
              </li>
              <li className={cx("main_list_item")}>
                <dl className={cx("main_item_description")}>
                  <dt className={cx("main_description_title")}>학력 : 학사</dt>
                  <dd className={cx("main_description_data")}>
                    <ol className={cx("main_data_list", "type_basic")}>
                      <li className={cx("main_list_item")}>
                        방송통신대학교 컴퓨터 과학과 학사(이학사)
                      </li>
                      <li className={cx("main_list_item")}>경민대학 졸업</li>
                      <li className={cx("main_list_item")}>
                        은곡공업고등학교 졸업
                      </li>
                    </ol>
                  </dd>
                </dl>
              </li>
              <li className={cx("main_list_item")}>
                <dl className={cx("main_item_description")}>
                  <dt className={cx("main_description_title")}>병역</dt>
                  <dd className={cx("main_description_data")}>
                    <ul className={cx("main_data_list", "type_basic")}>
                      <li className={cx("main_list_item")}>
                        20기계화보병사단 육군 병장 만기전역
                      </li>
                    </ul>
                  </dd>
                </dl>
              </li>
              <li className={cx("main_list_item")}>
                <dl className={cx("main_item_description")}>
                  <dt className={cx("main_description_title")}>거주지</dt>
                  <dd className={cx("main_description_data")}>
                    <ul className={cx("main_data_list", "type_basic")}>
                      <li className={cx("main_list_item")}>
                        경기도 남양주시 다산동
                      </li>
                    </ul>
                  </dd>
                </dl>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
